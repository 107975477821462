import { Button, Card, Col, Layout, Modal, Row } from 'antd';
import React, { useState } from 'react';
import '../../components/provider/Map.less';
import PnfProviderInfoDTO from '../../models/PnfProviderInfoDTO';
import Guid from '../../utils/Guid';
import ProviderMapPage from './ProviderMapPage';
import ProviderNominationPage from './ProviderNominationPage';
import ProviderType from '../../consts/ProviderType';
import ProviderDTO from '../../models/ProviderDTO';
import AddressDTO from '../../models/AddressDTO';
import Renderers from '../../utils/Renderers';
import Permission from '../../consts/Permission';
import { FileSearchOutlined } from '@ant-design/icons';
import useHasPermission from '../../hooks/useHasPermission';

const BalanceBillIcon = <FileSearchOutlined />;

const ImplementationToolPage = () => {
  const [providerInfo, setProviderInfo] = useState<(PnfProviderInfoDTO | undefined)[]>([]);
  const [providers, setProviders] = useState<(ProviderDTO | undefined)[]>([]);
  const [startedPNF, setStartedPNF] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const hasPermission = useHasPermission();
  
  const renderPnf = () => {
    if (startedPNF) {
      return <ProviderNominationPage isImplementationPage={true} provider={providerInfo} />;
    }
  };

  const renderCards = (provider: ProviderDTO | undefined) => {
    return (
      <>
        <Card
          size="small"
          title={<strong>{provider?.name}</strong>}
          headStyle={{ borderBottom: 0 }}
          bodyStyle={{ paddingTop: 0 }}
        >
          <Row gutter={12} justify="space-between" align="bottom" style={{ flexWrap: 'nowrap' }}>
            <Col style={{ maxWidth: providers.length == 1 ? '100%' : '77%', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
              <Row style={{ marginBottom: 10 }}>
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  <span>
                    <strong>Type:&nbsp;</strong>
                  </span>
                  <span style={{ marginRight: 12, color: 'rgba(0, 0, 0, 0.6)' }}>
                    {buildProviderTypeString(provider?.providerType ?? ProviderType.CLINIC)}&nbsp;
                  </span>
                  <span>
                    <strong>Specialty:&nbsp;</strong>
                  </span>
                  <span title={provider?.specialty || undefined} style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    {provider?.specialty}&nbsp;
                  </span>
                </div>
              </Row>
              <Row align="middle">
                <div style={{ marginBottom: 0, color: 'rgba(0, 0, 0, 1)', fontWeight: 500, lineHeight: 1.4 }}>
                  {Renderers.phoneNumberLink(provider?.phoneNumber ?? '')}
                </div>
                <h4 style={{ marginLeft: 12, marginBottom: 0 }}>{'NPI# ' + provider?.npi}</h4>
              </Row>
              <div
                style={{
                  marginTop: '0.75em',
                  marginBottom: 0,
                  color: 'rgba(0, 0, 0, 1)',
                  fontWeight: 500,
                  lineHeight: 1.3,
                  fontSize: '12px',
                }}
              >
                {buildAddress(provider)}
              </div>
              {provider?.balanceBill && hasPermission(Permission.TOOLS_PROVIDERDIRECTORY_VIEWBALANCEBILL) ? (
                <div style={{ marginTop: 5 }}>
                  <span style={{ color: '#1934B5' }}>
                    {BalanceBillIcon} <strong>Balance Bills</strong>{' '}
                  </span>
                </div>
              ) : null}
            </Col>
          </Row>
        </Card>
        <br />
      </>
    );
  };

  const buildProviderTypeString = (providerType: ProviderType) => {
    return providerType === ProviderType.CLINIC ? 'Facility' : 'Practitioner';
  };

  const buildAddress = (provider: ProviderDTO | undefined) => {
    if (provider) {
      return [
        provider.address1,
        ' ',
        provider.address2,
        '\n',
        provider.city,
        ' ',
        provider.state,
        ' ',
        provider.zipcode,
      ]
        .map((addressData: string | null, i) => {
          if (addressData === '\n') {
            return <br key={i} />;
          } else if (addressData === ' ') {
            return <span key={i}>&nbsp;</span>;
          } else if (addressData?.trim()?.length ?? -1 > 0) {
            if (addressData?.trim()) {
              return <span key={i}>{addressData.trim()}</span>;
            }
            return null;
          } else {
            return null;
          }
        })
        .filter((e) => e !== null);
    }
  };

  const modalContent = (providerInfo: (ProviderDTO | undefined)[]) => {
    return <>{providerInfo.map((x) => <Col style={{marginRight: '5px', maxWidth: providers.length == 1 ? '100%' : window.innerWidth < 400 ? '100%' : '40%'}} key={x?.id} xs={100} lg={8} xl={24} xxl={24}>{renderCards(x)}</Col>)}</>;
  };

  const onStartPnfClick = () => {
    const newProvider = providers.map((x) =>
      PnfProviderInfoDTO.create({
        ...x,
        providerTypeId: x?.providerType,
        address: AddressDTO.create({ ...x, zipCode: x?.zipcode }),
        practitionerFirstName: x?.firstName,
        practitionerLastName: x?.lastName,
        facilityName: x?.providerType == ProviderType.CLINIC ? x.name : null,
        phoneNumber: x?.phoneNumber?.replaceAll('-', ''),
        id: Guid.NewGuid(),
      })
    );

    setProviderInfo(newProvider);
    Modal.confirm({
      title: 'Selected Providers',
      content: <Row>{modalContent(providers)}</Row>,
      width: providers.length == 1 ? 'fit-content' : window.innerWidth < 500 ? '100%' : '60%',
      okText: 'Start PNF Submission',
      onOk: () => setStartedPNF(true),
    });
  };

  const handleProviderChange = (provider: (ProviderDTO | undefined)[]) => {
    if (provider) {
      setProviders(provider);
    }
  };

  const renderStartPnfButton = () => {
    if (providers && providers.length > 0 && !loading) {
      return (
        <Button type="primary" shape="round" size="large" onClick={onStartPnfClick}>
          Review Selected Providers
        </Button>
      );
    }
  };

  const onProviderSearch = (loading:boolean) =>{
    setLoading(loading)
  }

  const renderProviderSearch = () => {
    if (!startedPNF) {
      const providerSearchElement = (
        <>
          <ProviderMapPage onProviderSearch={onProviderSearch} onProviderSelected={handleProviderChange} isImplementationPage={true} />
          <br />
          <Row>
            <Col flex="auto"></Col>
            <Col>{renderStartPnfButton()}</Col>
          </Row>
        </>
      );

      return providerSearchElement;
    }
  };

  return (
    <Layout>
      <Layout.Content>
        {renderProviderSearch()}
        {renderPnf()}
      </Layout.Content>
    </Layout>
  );
};
export default ImplementationToolPage;
