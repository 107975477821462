import Icon, { RightOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, notification, Row, Select, Spin, Tooltip } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { useContext, useEffect, useState } from 'react';
import LookupsApiService from '../../api/LookupsApiService';
import ProviderMapSortBy from '../../consts/ProviderMapSortBy';
import ProviderType from '../../consts/ProviderType';
import Routes from '../../consts/Routes';
import SvgIcons from '../../consts/SvgIcons';
import useUrlQuery from '../../hooks/useUrlQuery';
import LookupTableDTO from '../../models/LookupTableDTO';
import ProviderSpecialtyDTO from '../../models/ProviderSpecialtyDTO';
import { GetLongLatLocation } from '../../pages/tools/ProviderMapPage';
import './Map.less';
import { AuthenticationContext } from '../../auth/AuthenticationContext';
import AccessRole from '../../consts/AccessRole';
import NpiAutoComplete from '../shared/NpiAutoComplete';

const MY_LOCATION = 'My location';
const MAP_LOCATION = 'Map location';

interface ProviderQuery {
  locationField: string;
  relativeLocation: number[] | undefined;
  searchRadius: number;
  npi: string;
  providerType: number | undefined;
  providerSpecialty: string[] | undefined;
  providersFilter: string | undefined;
  agreementType: string | undefined;
  agreementSubType: string | undefined;
  sortBy: number;
  auto: boolean;
}

interface ProviderSearchProps {
  isMobile: boolean;
  searchProviders: (locationField: string, query: ProviderQuery) => void;
  resultsCount: number;
  relativeLocation?: number[];
  hasLinkPermission: boolean;
  hasAgreementPermission: boolean;
  locationSearch?: number[];
  selectedCompassUrl?: string;
}

const SortByFormat = (val: number | undefined, pastTense?: boolean): string => {
  const sort = 'Sort' + (pastTense ? 'ed' : '');
  switch (val) {
    case ProviderMapSortBy.TYPE:
      return sort + ' by Type';
    case ProviderMapSortBy.SPECIALTY:
      return sort + ' by Specialty';
    case ProviderMapSortBy.NAME:
      return sort + ' by Name';
    default:
      return sort + ' by Distance';
  }
};

interface UrlQuery {
  location?: string;
  searchRadius?: string;
  npi?: string;
  providerType?: string;
  providerSpecialty?: string;
  providersFilter?: string;
  sortBy?: string;
}

const commonSelectProps = {
  style: { width: '100%' },
  size: 'large' as SizeType,
};
const ProviderSearch = (props: ProviderSearchProps) => {
  const [urlQuery] = useUrlQuery<UrlQuery>();

  const authCtx = useContext(AuthenticationContext);
  const userRole = authCtx.user?.accessRoleId ?? -1;

  let defaultDistance = 5;
  if ([AccessRole.CLAIMDOC, AccessRole.MEMBER].includes(userRole)) {
    defaultDistance = 10;
  }
  const reqProviderType = [AccessRole.TPA, AccessRole.BROKER, AccessRole.DPC, AccessRole.OTHERCOMPANY].includes(
    userRole
  );

  const [searchQuery, setSearchQuery] = useState<ProviderQuery>({
    locationField: urlQuery.location ?? '',
    relativeLocation: undefined,
    searchRadius: tryParseInt(urlQuery.searchRadius, defaultDistance),
    providerType: urlQuery.providerType
      ? tryParseInt(urlQuery.providerType, ProviderType.DOCTOR)
      : reqProviderType
      ? ProviderType.DOCTOR
      : undefined,
    providerSpecialty: urlQuery.providerSpecialty ? urlQuery.providerSpecialty.split(',') : undefined,
    providersFilter: urlQuery.providersFilter ? (urlQuery.providersFilter as string) : undefined,
    sortBy: tryParseInt(urlQuery.sortBy, ProviderMapSortBy.DISTANCE),
    agreementType: undefined,
    agreementSubType: undefined,
    auto: false,
    npi: urlQuery.npi ?? '',
  });
  const [providerSpecialties, setProviderSpecialties] = useState<string[]>([]);
  const [agreementCodes, setAgreementCodes] = useState<LookupTableDTO[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<LookupTableDTO[]>([]);
  const [expanded, setExpanded] = useState(props.isMobile ? true : false);
  const [autoCollapsedEnabled, setAutoCollapsedEnabled] = useState(true);

  const npiValid = searchQuery.npi.length == 10 || searchQuery.npi == '';
  const [searched, setSearched] = useState(false);
  
  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (props.locationSearch) {
      setSearchQuery({
        ...searchQuery,
        relativeLocation: props.locationSearch,
        locationField: MAP_LOCATION,
        auto: true,
      });
    }
  }, [props.locationSearch]);

  useEffect(() => {
    if (searchQuery.auto) {
      onQuery();
      setSearchQuery({ ...searchQuery, auto: false });
    }
  }, [searchQuery]);

  const copyLink = () => {
    if (props.hasLinkPermission) {
      const query = searchQuery;
      const urlQuery = {
        providerSpecialty: query.providerSpecialty?.join(','),
        providerType: query.providerType,
        providersFilter: query.providersFilter,
        searchRadius: query.searchRadius,
        sortBy: query.sortBy,
        npi: query.npi,
        location: query.relativeLocation ? undefined : query.locationField,
      };

      const url = window.location.origin + Routes.generate(Routes.PROVIDER_DIRECTORY, undefined, urlQuery);
      navigator.clipboard.writeText(url);
      notification.success({ message: 'Link Copied' });
    }
  };

  const onQuery = async () => {
    setSearched(true);
    if (isSearchDisabled() || !npiValid) {
      return;
    }

    setAutoCollapsedEnabled(true);
    props.searchProviders(searchQuery.locationField, searchQuery);
    setSearched(false);
  };

  const getLookups = async () => {
    let specs: ProviderSpecialtyDTO[] = [];
    try {
      specs = await LookupsApiService.getProviderSpecialties();
    } catch {
      specs = [];
    }

    let agreementCodes: LookupTableDTO[] = [];
    let agreementTypes: LookupTableDTO[] = [];
    try {
      agreementCodes = await LookupsApiService.getAgreementCodes();
    } catch {
      agreementCodes = [];
    }

    try {
      agreementTypes = await LookupsApiService.getAgreementTypes();
    } catch {
      agreementTypes = [];
    }

    setProviderSpecialties(specs.map((e) => e.name ?? '').filter((e) => e.trim().length > 0));
    setAgreementCodes(agreementCodes);
    setAgreementTypes(agreementTypes);
  };

  const getCodeFromSubTypeOnChange = (value?: string | null): string | undefined => {
    if (value?.toString().startsWith('Cond')) {
      return 'Cond';
    }

    if (value?.toString().startsWith('Global')) {
      return 'PL1';
    }

    return searchQuery.agreementType;
  };

  const isSearchDisabled = () => {
    return (
      searchQuery.relativeLocation === undefined &&
      (searchQuery.locationField.trim().length === 0 ||
        searchQuery.locationField === MY_LOCATION ||
        searchQuery.locationField === MAP_LOCATION) &&
      searchQuery.npi === ''
    );
  };

  const smartReplace = (initial: string, current: string): string => {
    if (current.includes(initial)) {
      return current.split(initial).join('');
    }
    return '';
  };

  const handleLocationFieldClicked = () => {
    if (searchQuery.locationField === MY_LOCATION || searchQuery.locationField === MAP_LOCATION) {
      setSearchQuery({ ...searchQuery, relativeLocation: undefined, locationField: '' });
    }
  };

  const searchRadiusFormat = (val: number): string => 'Within ' + val + ' miles';

  const providerTypeFormat = (val: number | undefined): string | undefined => {
    switch (val) {
      case ProviderType.CLINIC:
        return 'Facilities';
      case ProviderType.DOCTOR:
        return 'Practitioners';
      default:
        return 'Both';
    }
  };

  useEffect(() => {
    if (!props.isMobile) {
      setExpanded(true);
      setAutoCollapsedEnabled(false);
      return;
    }
    if (props.resultsCount > 0 && expanded && autoCollapsedEnabled) {
      setExpanded(false);
      setAutoCollapsedEnabled(false);
    }
  }, [props.resultsCount, props.isMobile, expanded]);

  const showNPIErrorMessage = () => {
    if (!searched) {
      return;
    }

    if (!npiValid) {
      return <Alert message="NPI number is invalid" type="error" />;
    }
  };

  return (
    <div
      onKeyUp={(e) => {
        if (e.key == 'Enter') {
          onQuery();
        }
      }}
    >
      <Row
        style={{ display: props.isMobile ? 'block' : 'none', marginBottom: 12 }}
        onClick={() => setExpanded(!expanded)}
      >
        <span className={expanded ? 'expanded-arrow' : 'collapsed-arrow'} style={{ cursor: 'pointer' }}>
          <RightOutlined />
        </span>
        &nbsp;
        <span style={{ cursor: 'pointer' }}>Search</span>
      </Row>
      <Row style={{ display: !expanded && props.isMobile ? 'none' : undefined }}>
        <Col style={{ width: '100%' }}>
          <Spin spinning={providerSpecialties.length === 0}>
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={16}>
                <Input
                  placeholder="Address/Zip Code"
                  allowClear
                  value={searchQuery.locationField}
                  size="large"
                  onChange={(e) => {
                    if (searchQuery.relativeLocation !== undefined) {
                      setSearchQuery({ ...searchQuery, relativeLocation: undefined });
                    }
                    if (searchQuery.locationField === MY_LOCATION || searchQuery.locationField === MAP_LOCATION) {
                      setSearchQuery({
                        ...searchQuery,
                        locationField: smartReplace(searchQuery.locationField, e.target.value),
                        relativeLocation: undefined,
                      });
                    } else {
                      setSearchQuery({ ...searchQuery, locationField: e.target.value, relativeLocation: undefined });
                    }
                  }}
                  onClick={handleLocationFieldClicked}
                  suffix={
                    <Icon
                      component={SvgIcons.UsrLocation}
                      onClick={() => {
                        GetLongLatLocation((result: number[] | null) => {
                          if (result) {
                            setSearchQuery({
                              ...searchQuery,
                              relativeLocation: result ?? undefined,
                              locationField: MY_LOCATION,
                            });
                          }
                        });
                      }}
                      aria-label="Use my location"
                    />
                  }
                  aria-label="Address or Zip Code"
                />
              </Col>
              <Col xs={16} sm={16} md={8}>
                <NpiAutoComplete
                  defaultValue={searchQuery.npi}
                  formItemName="npi"
                  onSelect={(e) => setSearchQuery({ ...searchQuery, ...e })}
                  onChange={(e) => setSearchQuery({ ...searchQuery, npi: e })}
                  placeholder={'NPI'}
                  size="large"
                  maxLength={10}
                  dropdownMatchSelectWidth={350}
                />
              </Col>
            </Row>

            <Row gutter={[12, 12]} style={{ marginTop: 12, marginBottom: 12 }}>
              <Col xs={24} sm={24} md={8}>
                <Select
                  value={searchRadiusFormat(searchQuery.searchRadius)}
                  {...commonSelectProps}
                  onSelect={(val: any) => {
                    setSearchQuery({ ...searchQuery, searchRadius: val });
                  }}
                  aria-label="Distance"
                >
                  <Select.Option value={5}>Within 5 miles</Select.Option>
                  <Select.Option value={10}>Within 10 miles</Select.Option>
                  <Select.Option value={20}>Within 20 miles</Select.Option>
                  <Select.Option value={50}>Within 50 miles</Select.Option>
                  <Select.Option value={100}>Within 100 miles</Select.Option>
                </Select>
              </Col>
              <Col xs={16} sm={16} md={8}>
                <Select
                  allowClear={!reqProviderType}
                  value={providerTypeFormat(searchQuery.providerType)}
                  placeholder="Provider Type"
                  {...commonSelectProps}
                  onSelect={(val: any) => {
                    setSearchQuery({ ...searchQuery, providerType: val });
                  }}
                  onClear={() => {
                    setSearchQuery({ ...searchQuery, providerType: undefined });
                  }}
                  aria-label="Provider Type"
                >
                  <Select.Option value={undefined}>Both</Select.Option>
                  <Select.Option value={ProviderType.DOCTOR}>Practitioners</Select.Option>
                  <Select.Option value={ProviderType.CLINIC}>Facilities</Select.Option>
                </Select>
              </Col>
              <Col xs={16} sm={16} md={8}>
                <Select
                  showSearch
                  optionFilterProp="prop"
                  notFoundContent={<h3 style={{ marginBottom: 0, paddingBottom: 0 }}>No results</h3>}
                  allowClear
                  value={searchQuery.providerSpecialty}
                  placeholder="Provider Specialty"
                  mode="multiple"
                  maxTagCount="responsive"
                  maxTagPlaceholder={(v: any[]) => {
                    if (!v || v?.length < 1) {
                      return <></>;
                    }
                    const allValues = v
                      .map((x) => x.label)
                      ?.reduce((prev?: string, curr?: string) => (prev ? prev : '') + ', ' + (curr ? curr : ''));
                    return <Tooltip title={allValues}>+ {v.length}...</Tooltip>;
                  }}
                  {...commonSelectProps}
                  onChange={(v: string[]) => {
                    setSearchQuery({ ...searchQuery, providerSpecialty: v });
                  }}
                  onClear={() => {
                    setSearchQuery({ ...searchQuery, providerSpecialty: undefined });
                  }}
                  aria-label="Provider Specialty"
                >
                  {providerSpecialties.map((v) => (
                    <Select.Option key={v} value={v} prop={v}>
                      {v}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            {props.hasAgreementPermission ? (
              <Row gutter={[12, 12]} style={{ marginBottom: 12 }}>
                <Col xs={24} sm={24} md={12}>
                  <Select
                    showSearch
                    optionFilterProp="prop"
                    notFoundContent={<h3 style={{ marginBottom: 0, paddingBottom: 0 }}>No results</h3>}
                    allowClear
                    value={searchQuery.agreementType?.toString()}
                    placeholder="Agreement Type"
                    {...commonSelectProps}
                    onSelect={(_: string, data: any) => {
                      setSearchQuery({ ...searchQuery, agreementType: data.prop, agreementSubType: undefined });
                    }}
                    onClear={() => {
                      setSearchQuery({ ...searchQuery, agreementType: undefined, agreementSubType: undefined });
                    }}
                    aria-label="Agreement Type"
                  >
                    {agreementCodes.map((v, i) => (
                      <Select.Option key={i} value={v.name} prop={v.name}>
                        {v.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Select
                    showSearch
                    optionFilterProp="prop"
                    notFoundContent={<h3 style={{ marginBottom: 0, paddingBottom: 0 }}>No results</h3>}
                    allowClear
                    value={searchQuery.agreementSubType?.toString()}
                    placeholder="Agreement Sub-Types"
                    {...commonSelectProps}
                    onSelect={(_: string, data: any) => {
                      setSearchQuery({
                        ...searchQuery,
                        agreementType: getCodeFromSubTypeOnChange(data.prop),
                        agreementSubType: data.prop,
                      });
                    }}
                    onClear={() => {
                      setSearchQuery({ ...searchQuery, agreementSubType: undefined });
                    }}
                    aria-label="Agreement Sub-Types"
                  >
                    {agreementTypes.map((v, i) => (
                      <Select.Option key={i} value={v.name} prop={v.name}>
                        {v.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            ) : null}
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={16}>
                <Input
                  allowClear
                  placeholder="Filter Providers..."
                  value={searchQuery.providersFilter ?? ''}
                  onChange={(e) => setSearchQuery({ ...searchQuery, providersFilter: e.target.value })}
                  style={{ width: '100%' }}
                  size="large"
                  aria-label="Filter Providers"
                />
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Select
                  value={SortByFormat(searchQuery.sortBy)}
                  style={{ width: '100%', marginBottom: 12 }}
                  size="large"
                  onSelect={(val: any) => {
                    setSearchQuery({ ...searchQuery, sortBy: val });
                  }}
                  aria-label="Sort By"
                >
                  <Select.Option value={1}>Sort by Distance</Select.Option>
                  <Select.Option value={2}>Sort by Type</Select.Option>
                  <Select.Option value={3}>Sort by Specialty</Select.Option>
                  <Select.Option value={4}>Sort by Name</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={12}>
              {props.hasLinkPermission ? (
                <>
                  <Col flex={1}>
                    <a href={props.selectedCompassUrl ?? '/'} target="_blank" rel="noreferrer">
                      <Button shape="round" block size="large" disabled={!props.selectedCompassUrl}>
                        Compass
                      </Button>
                    </a>
                  </Col>
                  <Col flex={1}>
                    <Button onClick={copyLink} shape="round" block size="large">
                      Copy Link
                    </Button>
                  </Col>
                </>
              ) : null}
              <Col flex={1}>
                <Button
                  type="primary"
                  size="large"
                  block
                  shape="round"
                  disabled={isSearchDisabled()}
                  onClick={onQuery}
                  style={{ marginBottom: 12 }}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <Row>{showNPIErrorMessage()}</Row>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export { ProviderSearch, ProviderQuery, MY_LOCATION, MAP_LOCATION, SortByFormat };
